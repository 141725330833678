import React from 'react';
import profile from './my-profile.svg';
import './App.css';
import {ReactComponent as PersonalIcon} from './person-website-icon.svg';
import {ReactComponent as AtiniIcon} from './atini-logo.svg';
import {ReactComponent as LinkedInIcon} from './linkedin-icon.svg';
import {ReactComponent as UpworkIcon} from './upwork-icon.svg';
import {ReactComponent as GithubIcon} from './github-icon.svg';


const links = [
  {
    title: 'Personal Website',
    url: 'https://christine-ting.com',
    Icon: PersonalIcon,
  },
  {
    title: 'Atini Studio',
    url: 'https://atinistudio.com',
    Icon: AtiniIcon,
  },
  {
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/in/christine-ting',
    Icon: LinkedInIcon,
  },
  {
    title: 'Upwork',
    url: 'https://www.upwork.com/freelancers/~019d03f3f0b2d812c3',
    Icon: UpworkIcon,
  },
  {
    title: 'GitHub',
    url: 'https://github.com/christine-ting',
    Icon: GithubIcon,
  },
]

function App() {
  return (
    <div className="App">
      <div className="container">
        <img src={profile} className="profile-pic" alt="profile" />
        <div className="main-text">Christine Ting</div>
        <div className="sub-text">Entrepreneur | Software Engineer | UC Berkeley Alumni</div>
        <div className="info-text"><a href="mailto:'christine.ting@atinistudio.com">christine.ting@atinistudio.com</a> | <a href="tel:+9095032187">(909) 503-2187</a></div>
        <div className="links">
          {links.map(({title, Icon, url}, index) => {
            const size = title === 'LinkedIn' ? '20' : title === 'upwork' ? '26' : '22';
            const view = title === 'Upwork' ? '56.7' : '24';
            return (
              <div className="button-container"  onClick={() => window.open(url, '_blank')}>
                <div className="icon-container">
                <Icon height={28} width={28}/>
                  </div>
                {/* <svg
                  key={index}
                  xmlns="http://www.w3.org/2000/svg"
                  width={size}
                  height={size}
                  fill="white"
                  viewBox={`0 0 ${view} ${view}`}
                >
                  <path d={link.icon} />
                </svg> */}
                <div className="button-text" key={index}>{title}</div>
              </div>
            )
          }
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
